import { translate } from '@editor/common/utils/translations'

export const META_TITLE_MAX_COUNT = 60
export const META_DESCRIPTION_MAX_COUNT = 160
export const META_KEYWORDS_MAX_COUNT = 1000

export const UPGRADE_BANNER_INFO_LIST = [
  translate('meta_title_tag_label'),
  translate('meta_description_label'),
  translate('meta_keywords_label')
]

export const UPGRADE_BANNER_BACKGROUND =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade_banner_background_2.svg'
export const UPGRADE_IMAGE =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade_image.svg'
export const UPGRADE_IMAGE_HOVER =
  'https://static.rfstat.com/renderforest/images/website_maker_images/upgrade_image_hover.svg'
export const META_DESCRIPTION_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/meta_description.svg'
export const META_TITLE_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/meta_title_tag.svg'
export const META_KEYWORDS_IMG =
  'https://static.rfstat.com/renderforest/images/website_maker_images/meta_keywords.svg'
