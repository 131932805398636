import styled from 'styled-components'
import { UPGRADE_BANNER_BACKGROUND } from './consts'

export const DescriptionWrapper = styled.div`
  padding: 20px 0 3px 5px;
  @media only screen and (max-width: 580px) {
    padding: 25px 0 3px 5px;
  }
  & > div > div {
    font-size: 14px;
    color: #545f7e;
    font-weight: 400;
  }
`

export const CheckBoxWrapper = styled.div`
  display: flex;
  padding-top: 10px;
  color: #363f5a;
  font-size: 14px;
`

export const CheckBox = styled.div`
  position: relative;
  width: 18px;
  height: 18px;
  display: flex;
  box-sizing: border-box;
  margin-right: 10px;
  background: ${({ isChecked }) =>
    isChecked
      ? 'linear-gradient(180deg, #58F17B 0%, #16CD6F 100%) 0% 0% no-repeat'
      : 'white'};
  border: ${({ isChecked }) => (isChecked ? 'none' : '1px solid #DDE3F0')};
  border-radius: 4px;
  cursor: pointer;
  & > div {
    position: absolute;
    left: 2px;
    top: 2px;
  }
`

export const PleaseNoteInfo = styled.div`
  width: 85%;
  position: relative;
  padding: 10px 30px 10px 45px;
  box-sizing: border-box;
  background-color: rgb(254 248 230);
  border-radius: 10px;
  font-size: 14px;
  color: #545f7e;
  & > div {
    position: absolute;
    top: 12px;
    left: 20px;
  }
  & > span > span {
    font-weight: 500;
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`

export const MetaDataInfo = styled.div`
  display: flex;
  align-items: baseline;
  padding-top: 10px;
  font-size: 14px;
  color: #545f7e;
  & > div {
    border-radius: 10px;
    width: 16px;
    height: 16px;
    background: linear-gradient(180deg, #7bf596 0%, #4fdc6f 100%);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
  }
  & > span {
    margin-left: 5px;
    & > span {
      margin-right: 5px;
      color: #387dff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`

export const Forms = styled.div`
  padding-bottom: 20px;
`

export const UpgradeBannerWrapper = styled.div`
  .upgrade-banner {
    min-height: 183px;
    background-image: url(${UPGRADE_BANNER_BACKGROUND});

    @media only screen and (max-width: 665px) {
      min-height: 250px;
      background-size: cover;
      img {
        top: 20px;
        bottom: auto;
      }
    }

    @media only screen and (min-width: 666px) {
      & > div {
        left: 175px;
      }
      & > img {
        width: auto;
        height: auto;
      }
    }

    &:hover div {
      @media only screen and (min-width: 769px) {
        left: 185px;
      }
      @media only screen and (min-width: 666px) and (max-width: 768px) {
        left: 175px;
      }
    }
  }
`

export const CheckBoxText = styled.div`
  width: calc(100% - 28px);
`
