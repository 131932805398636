// @flow
import React, { memo } from 'react'

import { translate } from '@editor/common/utils/translations'
import * as Styled from './styled'
import type { TDisabledInputProps } from './types'

const DisabledInput = ({
  value,
  isTextArea,
  onHomePageClick
}: TDisabledInputProps) => {
  return value ? (
    <Styled.DisabledInput isTextArea={isTextArea} hasValue={value}>
      {value}
    </Styled.DisabledInput>
  ) : (
    <Styled.DisabledInput isTextArea={isTextArea}>
      <span>
        {translate('enter_metadata_label')}
        <span onClick={onHomePageClick}>Homepage</span>.
      </span>
    </Styled.DisabledInput>
  )
}

export default memo(DisabledInput)
