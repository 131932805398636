// @flow
import React, { useCallback, useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TPathNameProps } from './types'
import {
  InputContainer,
  ErrorMessage,
  ErrorIcon,
  Input
} from '../../../shared-components/styled'
import { PAGE_NAME_VALIDATION_MESSAGE } from './consts'
import { validatePageName, isPageNameDuplicates } from './utils'
import { PAGE_BASE } from '@editor/conf/routes'

const PAGE_NAME_EXISTENCE_MESSAGE = translate('this_page_name_already_exists')

const PathName = ({
  pathName,
  pathNames,
  staticPathName,
  setPathName,
  hasChanges
}: TPathNameProps) => {
  const {
    params: { page }
  } = useRouteMatch(PAGE_BASE)

  const [validationMessage, setValidationMessage] = useState('')
  const [isInputActive, setInputActiveState] = useState(false)

  useEffect(() => {
    if (isPageNameDuplicates(pathName, pathNames)) {
      setValidationMessage(PAGE_NAME_EXISTENCE_MESSAGE)
    } else {
      setValidationMessage('')
    }
  }, [pathName, pathNames])

  const handleFocus = useCallback(() => {
    setInputActiveState(true)
  }, [])

  const handleBlur = useCallback(() => {
    setInputActiveState(false)

    if (pathName === '') {
      setPathName(staticPathName)
    }
  }, [page, pathName, hasChanges, setPathName])

  const handleInputChange = useCallback(
    e => {
      const { value } = e.target
      const _pathName = value.replace(' ', '_')

      if (validatePageName(_pathName)) {
        setValidationMessage('')
        setPathName(_pathName)
      } else {
        setValidationMessage(PAGE_NAME_VALIDATION_MESSAGE)
      }
    },
    [setPathName]
  )

  return (
    <InputContainer>
      <Styled.Title>{translate('path_name_label')}</Styled.Title>
      <Styled.InputWrapper>
        <Input
          type="text"
          value={pathName}
          placeholder="page_name"
          isInputActive={isInputActive}
          hasError={validationMessage}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
        {validationMessage ? (
          <ErrorMessage>
            <ErrorIcon name="warning" size="xnormal" color="#ff4c4c" />
            {validationMessage}
          </ErrorMessage>
        ) : null}
      </Styled.InputWrapper>
    </InputContainer>
  )
}

export default PathName
