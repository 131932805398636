// @flow
import React, { memo, useCallback, useState } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from '../../styled'
import type { TMetaKeywordsProps } from './types'
import { META_KEYWORDS_IMG, META_KEYWORDS_MAX_COUNT } from '../../consts'
import {
  Input,
  TitleContainer as Description
} from '../../../../../shared-components'
import DisabledInput from '../DisabledInput'

const META_KEYWORDS = translate('meta_keywords_label')
const META_KEYWORDS_PLACEHOLDER = translate('meta_keywords_placeholder_label')
const META_KEYWORDS_TOOLTIP_TEXT = translate('meta_keywords_tooltip_text')

const MetaKeywords = ({
  metaKeywords,
  isDisabled,
  updateMetaData,
  onHomePageClick
}: TMetaKeywordsProps) => {
  const [isCharLimitExceed, setCharLimitExceedState] = useState(false)

  const handleChange = useCallback(
    value => {
      if (value.length > META_KEYWORDS_MAX_COUNT) {
        setCharLimitExceedState(true)
        return
      }

      updateMetaData('keywords', value)
      setCharLimitExceedState(false)
    },
    [updateMetaData]
  )

  const handlePaste = useCallback(
    e => {
      e.preventDefault()
      const copiedText = e.clipboardData.getData('text')
      const currentText = `${metaKeywords}${copiedText}`

      updateMetaData('keywords', currentText.slice(0, META_KEYWORDS_MAX_COUNT))
    },
    [metaKeywords, updateMetaData]
  )

  const handleBlur = useCallback(() => {
    setCharLimitExceedState(false)
  }, [])

  const hasInputError =
    metaKeywords &&
    metaKeywords.length === META_KEYWORDS_MAX_COUNT &&
    isCharLimitExceed

  return (
    <>
      <Styled.DescriptionWrapper>
        <Description
          title={META_KEYWORDS}
          tooltipText={META_KEYWORDS_TOOLTIP_TEXT}
          imgSrc={META_KEYWORDS_IMG}
          hasTooltipContent
        />
      </Styled.DescriptionWrapper>
      {isDisabled ? (
        <DisabledInput
          isTextArea
          value={metaKeywords}
          onHomePageClick={onHomePageClick}
        />
      ) : (
        <Input
          isTextArea
          value={metaKeywords}
          hasError={hasInputError}
          count={META_KEYWORDS_MAX_COUNT}
          placeholder={META_KEYWORDS_PLACEHOLDER}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
        />
      )}
    </>
  )
}

export default memo(MetaKeywords)
