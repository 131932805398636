// @flow
import React, { useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'
import { transformTextVariables } from '@src/editor/common/utils/text-transformation'
import { BoldComp } from '@editor/common/styled-components/simple-tags'
import { CustomLinkWithProps } from '@editor/common/styled-components/simple-tags-with-props'
import * as Styled from './styled'
import type { TPageMetaDataProps } from './types'
import {
  UPGRADE_BANNER_INFO_LIST,
  UPGRADE_IMAGE,
  UPGRADE_IMAGE_HOVER
} from './consts'
import { UpgradeBanner } from '../../../shared-components'
import { Title, InputContainer } from '../../../shared-components/styled'
import { MetaDescription, MetaKeywords, MetaTitle } from './components'
import Icon from '@renderforest/rf-ui-library/atoms/Icon'
import { isSubscriber } from '@selectors'
import { useHistoryWithSearch } from '@hooks'
import { EDIT_BASE } from '@editor/conf/routes'

const UPGRADE_INFO = translate('unlock_more_seo_features_label')
const UPGRADE_BTN_TEXT = translate('unlock_label')

const PageMetaData = ({
  isSubscriber,
  isHomePage,
  isUsedForAll,
  metaTitle,
  metaDescription,
  metaKeywords,
  updateMetaData,
  setActiveTab
}: TPageMetaDataProps) => {
  const { url } = useRouteMatch(EDIT_BASE)
  const history = useHistoryWithSearch()

  const handleCheckBoxClick = useCallback(() => {
    updateMetaData('useForAll', !isUsedForAll)
  }, [isUsedForAll, updateMetaData])

  const onHomePageClick = useCallback(() => {
    setActiveTab('home')
    history.push(`${url}/home/page-settings`)
  }, [setActiveTab])

  const isDisabled = !isHomePage && isUsedForAll

  return isSubscriber ? (
    <div>
      <InputContainer>
        <Title>{translate('page_meta_data_label')}</Title>
        {isDisabled ? (
          <Styled.MetaDataInfo>
            <Icon name="check" color="white" size="xxxSmall" />
            <span>
              {transformTextVariables(translate('homepage_is_set_as_default'), [
                {
                  value: 'Homepage',
                  Comp: CustomLinkWithProps,
                  props: {
                    onClick: onHomePageClick
                  }
                }
              ])}
            </span>
          </Styled.MetaDataInfo>
        ) : null}
        <Styled.Forms>
          <MetaTitle
            metaTitle={metaTitle}
            isDisabled={isDisabled}
            updateMetaData={updateMetaData}
            onHomePageClick={onHomePageClick}
          />
          <MetaDescription
            metaDescription={metaDescription}
            isDisabled={isDisabled}
            updateMetaData={updateMetaData}
            onHomePageClick={onHomePageClick}
          />
          <MetaKeywords
            metaKeywords={metaKeywords}
            isDisabled={isDisabled}
            updateMetaData={updateMetaData}
            onHomePageClick={onHomePageClick}
          />
        </Styled.Forms>
        {isHomePage ? (
          <Styled.CheckBoxWrapper>
            <Styled.CheckBox
              role="checkbox"
              onClick={handleCheckBoxClick}
              isChecked={isUsedForAll}
            >
              {isUsedForAll ? (
                <Icon name="check" size="xSmall" color="white" />
              ) : null}
            </Styled.CheckBox>
            <Styled.CheckBoxText>
              {translate('use_the_metadata_label')}
            </Styled.CheckBoxText>
          </Styled.CheckBoxWrapper>
        ) : null}
      </InputContainer>
      <Styled.PleaseNoteInfo>
        <Icon name="warning" size="xSmall" color="#E79E00" />
        <span>
          {transformTextVariables(translate('page_settings_note_label'), [
            {
              value: translate('page_settings_note_label_tr1'),
              Comp: BoldComp
            }
          ])}
        </span>
      </Styled.PleaseNoteInfo>
    </div>
  ) : (
    <Styled.UpgradeBannerWrapper>
      <Title>{translate('page_meta_data_label')}</Title>
      <UpgradeBanner
        btnText={UPGRADE_BTN_TEXT}
        className="upgrade-banner"
        customTranslationAlignment={165}
        img={UPGRADE_IMAGE}
        hoverImg={UPGRADE_IMAGE_HOVER}
        info={UPGRADE_INFO}
        infoList={UPGRADE_BANNER_INFO_LIST}
      />
    </Styled.UpgradeBannerWrapper>
  )
}

const mapStateToProps = state => ({
  isSubscriber: isSubscriber(state)
})

export default connect(mapStateToProps)(PageMetaData)
