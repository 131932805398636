import styled from 'styled-components'
import SemrushDependedPopup from '@editor/common/components/SemrushDependedPopup'
import { scrollBarStyles } from '@editor/common/styles'

export const PageSettingsPopup = styled(SemrushDependedPopup)`
  & > div {
    padding: 0;
    max-height: 800px;
    max-width: 800px;
    & > div {
      padding: 0;
    }
  }
  @media only screen and (max-width: 768px) {
    height: 100%;
    & > div {
      max-height: 100%;
      max-width: 100%;
      height: 100%;
      width: 100%;
    }
  }
  @media only screen and (min-width: 769px) and (max-width: 889px) {
    & > div {
      width: 90%;
    }
  }

  @media only screen and (min-width: 769px) and (max-height: 850px) {
    & > div {
      max-height: 90%;
      height: 100%;
      & > div {
        height: 100%;
      }
    }
  }
`

export const Container = styled.div`
  position: relative;
  padding-top: 30px;
  width: 800px;
  height: 800px;
  overflow: hidden;
  box-sizing: border-box;
  @media only screen and (max-width: 768px) {
    width: 100%;
    box-sizing: border-box;
    height: 100%;
  }
  @media only screen and (min-width: 769px) and (max-width: 889px) {
    width: 100%;
  }
`

export const Title = styled.p`
  color: #545f7e;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  padding-bottom: 20px;
`

export const Content = styled.div`
  padding: 20px 40px;
  position: relative;
  overflow-y: scroll;
  height: calc(100% - 250px);
  ${scrollBarStyles}

  @media only screen and (max-width: 768px) {
    padding: 20px;
    box-sizing: border-box;
    height: calc(100% - 210px);
  }
`
