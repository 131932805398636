// @flow
import React, { memo, useCallback, useState } from 'react'
import { translate } from '@editor/common/utils/translations'

import * as Styled from '../../styled'
import type { TMetaTitleProps } from './types'
import { META_TITLE_IMG, META_TITLE_MAX_COUNT } from '../../consts'
import {
  Input,
  TitleContainer as Description
} from '../../../../../shared-components'
import DisabledInput from '../DisabledInput'

const META_TITLE_TAG = translate('meta_title_tag_label')
const META_TITLE_TAG_TOOLTIP_TEXT = translate('your_page_title_tooltip_text')
const META_TITLE_TAG_PLACEHOLDER = translate('your_page_title_placeholder')

const MetaTitle = ({
  metaTitle,
  isDisabled,
  updateMetaData,
  onHomePageClick
}: TMetaTitleProps) => {
  const [isCharLimitExceed, setCharLimitExceedState] = useState(false)

  const handleChange = useCallback(
    value => {
      if (value.length > META_TITLE_MAX_COUNT) {
        setCharLimitExceedState(true)
        return
      }

      updateMetaData('title', value)
      setCharLimitExceedState(false)
    },
    [updateMetaData]
  )

  const handlePaste = useCallback(
    e => {
      e.preventDefault()
      const copiedText = e.clipboardData.getData('text')
      const currentText = `${metaTitle}${copiedText}`

      updateMetaData('title', currentText.slice(0, META_TITLE_MAX_COUNT))
    },
    [metaTitle, updateMetaData]
  )

  const handleBlur = useCallback(() => {
    setCharLimitExceedState(false)
  }, [])

  const hasInputError =
    metaTitle && metaTitle.length === META_TITLE_MAX_COUNT && isCharLimitExceed

  return (
    <>
      <Styled.DescriptionWrapper>
        <Description
          title={META_TITLE_TAG}
          tooltipText={META_TITLE_TAG_TOOLTIP_TEXT}
          imgSrc={META_TITLE_IMG}
          hasTooltipContent
        />
      </Styled.DescriptionWrapper>
      {isDisabled ? (
        <DisabledInput value={metaTitle} onHomePageClick={onHomePageClick} />
      ) : (
        <Input
          value={metaTitle}
          hasError={hasInputError}
          count={META_TITLE_MAX_COUNT}
          placeholder={META_TITLE_TAG_PLACEHOLDER}
          onChange={handleChange}
          onPaste={handlePaste}
          onBlur={handleBlur}
        />
      )}
    </>
  )
}

export default memo(MetaTitle)
