// @flow

export const checkDuplicatedPathName = (pathNames: [string]): boolean => {
  const pathNamesMap = new Map()

  for (let i = 0; i <= pathNames.length; i++) {
    const pathName = pathNames[i]
    if (pathNamesMap.has(pathName)) {
      return true
    }
    pathNamesMap.set(pathName, true)
  }
  return false
}
