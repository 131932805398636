import styled from 'styled-components'

export const DisabledInput = styled.div`
  width: 85%;
  min-height: ${({ isTextArea }) => (isTextArea ? '150px' : 'auto')};
  display: flex;
  font-size: 14px;
  border-radius: 10px;
  padding: 11px 15px;
  box-sizing: border-box;
  color: ${({ hasValue }) => (hasValue ? '#363f5a' : '#545F7E')};
  background-color: #f5fafe;
  & > span {
    width: 100%;
    & > span {
      margin-left: 5px;
      color: #387dff;
      text-decoration: underline;
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 768px) {
    width: 100%;
  }
`
