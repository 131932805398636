// @flow

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'

import * as Styled from './styled'
import type { TPageSettingsProps } from './types'
import PageSettingsContainer from './PageSettingsContainer'
import { SettingsLoader } from '@editor/common/components/Loaders'
import ConfirmDialog from '@editor/common/components/ConfirmDialog-old'
import {
  DIALOG_CONTENT,
  DISCARD_DIALOG_BUTTONS,
  DISCARD_DIALOG_TITLE
} from '@containers/edit/containers/settings/consts'
import { selectIsSettingsLoading } from '@selectors'
import { useHistoryWithSearch } from '@hooks'
import { PAGE_BASE } from '@editor/conf/routes'

const PageSettings = ({ isLoading }: TPageSettingsProps) => {
  const [hasChanges, setChangesState] = useState(false)
  const [isConfirmDialogOpen, setConfirmDialogOpenState] = useState(false)

  const { url } = useRouteMatch(PAGE_BASE)
  const history = useHistoryWithSearch()

  const popupRef = useRef(null)

  const onClose = useCallback(() => {
    history.push(url)
  }, [url])

  useEffect(() => {
    const popupEl = document.querySelector('.page-settings-popup')
    const onPopupClose = e => {
      if (popupRef.current && !popupRef.current.contains(e.target)) {
        if (hasChanges) {
          e.stopPropagation()
          setConfirmDialogOpenState(true)
        }
      }
    }

    popupEl.addEventListener('mousedown', onPopupClose, true)

    return () => {
      popupEl.removeEventListener('mousedown', onPopupClose, true)
    }
  }, [popupRef.current, hasChanges])

  return (
    <>
      <Styled.PageSettingsPopup
        isOpen={true}
        onClose={onClose}
        isMobileFullScreen
        className="page-settings-popup"
      >
        {isLoading && <SettingsLoader />}
        <PageSettingsContainer
          containerRef={popupRef}
          closePopup={onClose}
          setChangesState={setChangesState}
          setConfirmDialogOpenState={setConfirmDialogOpenState}
        />
      </Styled.PageSettingsPopup>
      <ConfirmDialog
        isOpen={isConfirmDialogOpen}
        title={DISCARD_DIALOG_TITLE}
        content={DIALOG_CONTENT}
        buttons={DISCARD_DIALOG_BUTTONS}
        onConfirm={onClose}
        onCancel={() => setConfirmDialogOpenState(false)}
      />
    </>
  )
}

const mapStateToProps = state => ({
  isLoading: selectIsSettingsLoading(state)
})

export default connect(mapStateToProps)(PageSettings)
