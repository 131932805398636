import styled from 'styled-components'

import {
  Title as _Title,
  InputWrapper as _InputWrapper
} from '../../../shared-components/styled'

export const Title = styled(_Title)`
  padding-bottom: 20px;
`

export const InputWrapper = styled(_InputWrapper)`
  position: relative;
  input {
    padding-left: 145px;
  }
  &:before {
    content: 'your_domain.com/';
    position: absolute;
    left: 0;
    top: 50%;
    font-size: 14px;
    color: #9aa6c8;
    padding-left: 15px;
    transform: translateY(-50%);
  }
`
