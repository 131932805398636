import styled from 'styled-components'

export const TabsContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  background-color: #f5fafe;
  padding-top: 15px;
  border-top: 1px solid #dee3f0;
  border-bottom: 1px solid #dee3f0;
`

export const Title = styled.p`
  color: #363f5a;
  font-size: 16px;
  font-weight: 500;
  padding: 0 0 5px 40px;
  user-select: none;
  line-height: 1;
  @media only screen and (max-width: 768px) {
    padding: 0 0 5px 20px;
  }
`

export const TabsWrapper = styled.div`
  display: flex;
  align-items: center;
`

export const Tabs = styled.ul`
  display: flex;
  list-style-type: none;
  overflow-x: scroll;
  scroll-behavior: smooth;
  ::-webkit-scrollbar {
    display: none;
  }
`

export const Tab = styled.li`
  max-width: 152px;
  cursor: pointer;
  padding: 6px 15px;
  margin: 15px 0;
  border-radius: 50px;
  box-sizing: border-box;
  background-color: ${({ isActive }) => (isActive ? '#387dff' : 'transparent')};
  box-shadow: ${({ isSelected }) =>
    isSelected ? '0 3px 6px #387dff33' : 'none'};
  user-select: none;
  p {
    font-size: 14px;
    font-weight: 500;
    color: ${({ isActive }) => (isActive ? '#ffffff' : '#7683a8')};
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  &:hover {
    p {
      color: ${({ isActive }) => (isActive ? '#ffffff' : '#363f5a')};
    }
  }
`

export const IconWrapper = styled.div`
  width: 40px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 20px;
  }
`
