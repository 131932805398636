// @flow
import React, { useState, useRef, useEffect, useCallback } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TTabsProps } from './types'
import Icon from '@website/common/components/Icon'
import { useHistoryWithSearch } from '@hooks'
import { getAllPages } from '@selectors'
import { EDIT_BASE } from '@editor/conf/routes'

const Tabs = ({ pages, activeTab, setActiveTab }: TTabsProps) => {
  const [showLeftIcon, setLeftIconVisibleState] = useState(false)
  const [showRightIcon, setRightIconVisibleState] = useState(false)

  const { url } = useRouteMatch(EDIT_BASE)
  const history = useHistoryWithSearch()

  const tabsRef = useRef(null)
  const tabsData = pages.map(page => ({ label: page, value: page }))

  useEffect(() => {
    const { current: scrollableElem } = tabsRef

    if (
      scrollableElem &&
      scrollableElem.clientWidth < scrollableElem.scrollWidth
    ) {
      setRightIconVisibleState(true)
    }
  }, [tabsRef])

  useEffect(() => {
    const elem = document.getElementById(activeTab)

    elem &&
      setTimeout(() => {
        elem.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center'
        })
      }, 0)
  }, [activeTab])

  const handleScroll = useCallback(e => {
    const { target: elem } = e

    if (elem.scrollWidth - elem.scrollLeft === elem.clientWidth) {
      setRightIconVisibleState(false)
    } else {
      setRightIconVisibleState(true)
    }

    if (elem.scrollLeft > 0) {
      setLeftIconVisibleState(true)
    } else {
      setLeftIconVisibleState(false)
    }
  }, [])

  const onRightIconClick = useCallback(() => {
    const { current: scrollableElem } = tabsRef

    if (scrollableElem) {
      scrollableElem.scrollLeft += 50
    }
  }, [tabsRef])

  const onLeftIconClick = useCallback(() => {
    const { current: scrollableElem } = tabsRef

    if (scrollableElem) {
      scrollableElem.scrollLeft -= 50
    }
  }, [tabsRef])

  const onTabClick = useCallback(
    value => {
      setActiveTab(value)
      history.push(`${url}/${value}/page-settings`)
    },
    [history, url]
  )

  return (
    <Styled.TabsContainer>
      <Styled.Title>{translate('your_pages_label')}</Styled.Title>
      <Styled.TabsWrapper>
        <Styled.IconWrapper>
          {showLeftIcon ? (
            <Icon
              name="back_arrow"
              size="middle"
              color="#545F7E"
              onClick={onLeftIconClick}
            />
          ) : null}
        </Styled.IconWrapper>
        <Styled.Tabs ref={tabsRef} role="tablist" onScroll={handleScroll}>
          {tabsData.map(({ label, value }) => (
            <Styled.Tab
              role="tab"
              id={value}
              key={value}
              isActive={activeTab === value}
              onClick={() => onTabClick(value)}
            >
              <p>{label}</p>
            </Styled.Tab>
          ))}
        </Styled.Tabs>
        <Styled.IconWrapper>
          {showRightIcon ? (
            <Icon
              name="next_arrow"
              size="middle"
              color="#545F7E"
              onClick={onRightIconClick}
            />
          ) : null}
        </Styled.IconWrapper>
      </Styled.TabsWrapper>
    </Styled.TabsContainer>
  )
}

const mapStateToProps = state => ({
  pages: getAllPages(state)
})

export default connect(mapStateToProps)(Tabs)
