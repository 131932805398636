// @flow
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { connect } from 'react-redux'
import { useRouteMatch } from 'react-router-dom'
import { translate } from '@editor/common/utils/translations'

import * as Styled from './styled'
import type { TPageSettingsContainerProps } from './types'
import { Footer } from '../shared-components'
import { Tabs, PathName, PageMetaData } from './components'
import { useHistoryWithSearch } from '@hooks'
import { getAllPages, getPagesMeta } from '@selectors'
import { changePageNames, changePageSettings } from '@actions/project'
import { checkDuplicatedPathName } from './utils'
import { EDIT_BASE, PAGE_BASE } from '@editor/conf/routes'

const PageSettingsContainer = ({
  pages,
  pagesMetaData,
  containerRef,
  closePopup,
  setChangesState,
  setConfirmDialogOpenState,
  changePageNames,
  changePageSettings
}: TPageSettingsContainerProps) => {
  const {
    params: { page }
  } = useRouteMatch(PAGE_BASE)
  const { url: urlToPages } = useRouteMatch(EDIT_BASE)
  const history = useHistoryWithSearch()

  const initialMetaData = [
    { useForAll: false, ...pagesMetaData[0] },
    ...pagesMetaData.slice(1)
  ]
  const [activeTab, setActiveTab] = useState(page)
  const [pathNames, setPathNames] = useState(pages)
  const [metaData, setMetaData] = useState(initialMetaData)

  const { useForAll } = metaData[0]
  const isHomePage = activeTab === 'home'
  const activePageIdx = useMemo(
    () => pages.findIndex(pageName => pageName === activeTab),
    [activeTab, pages]
  )
  const activePathName = pathNames[activePageIdx]
  const staticPathName = pages[activePageIdx]

  const pageMetaData = useMemo(() => {
    const _activePageIdx = activePageIdx === -1 ? 0 : activePageIdx

    return useForAll ? metaData[0] : metaData[_activePageIdx]
  }, [metaData, activePageIdx, useForAll])

  const hasPathNameChanges = useMemo(
    () => pathNames.some((pathName, idx) => pathName !== pages[idx]),
    [pathNames, pages]
  )

  const hasMetaDataChanges = useMemo(
    () => JSON.stringify(initialMetaData) !== JSON.stringify(metaData),
    [initialMetaData, metaData]
  )

  const hasChanges = useMemo(
    () => hasPathNameChanges || hasMetaDataChanges,
    [hasPathNameChanges, hasMetaDataChanges]
  )

  const isSaveBtnDisabled = useMemo(() => {
    const hasDuplicatedPathName = checkDuplicatedPathName(pathNames)
    return !hasChanges || hasDuplicatedPathName
  }, [hasChanges, activePathName, pages])

  useEffect(() => {
    setChangesState(hasChanges)
  }, [hasChanges])

  const updateMetaData = useCallback(
    (key, value) => {
      const _metaData = [
        ...metaData.slice(0, activePageIdx),
        { ...metaData[activePageIdx], [key]: value },
        ...metaData.slice(activePageIdx + 1)
      ]

      setMetaData(_metaData)
    },
    [activePageIdx, metaData]
  )

  const updatePathName = useCallback(
    newPathName => {
      const newPathNames = [
        ...pathNames.slice(0, activePageIdx),
        newPathName,
        ...pathNames.slice(activePageIdx + 1)
      ]
      setPathNames(newPathNames)
    },
    [activePageIdx, pathNames]
  )

  const onSave = useCallback(() => {
    if (isSaveBtnDisabled) {
      return
    }
    if (hasPathNameChanges) {
      changePageNames(pathNames)
      history.push(`${urlToPages}/${activePathName}/page-settings`)
      setActiveTab(activePathName)
    }

    if (hasMetaDataChanges) {
      changePageSettings(metaData)
    }
  }, [
    history,
    metaData,
    activeTab,
    pathNames,
    urlToPages,
    activePathName,
    hasMetaDataChanges,
    changePageNames,
    changePageSettings
  ])

  const onDiscard = useCallback(() => {
    if (hasChanges) {
      setConfirmDialogOpenState(true)
      return
    }

    closePopup()
  }, [hasChanges, closePopup])

  const scrollToTopOfContent = () => {
    const content = document.querySelector('.content')
    content.scrollTop = 0
  }

  const _setActiveTab = useCallback(tab => {
    setActiveTab(tab)
    scrollToTopOfContent()
  }, [])

  const { title, description, keywords } = pageMetaData

  return (
    <Styled.Container ref={containerRef}>
      <Styled.Title>{translate('page_settings_label')}</Styled.Title>
      <Tabs activeTab={activeTab} setActiveTab={_setActiveTab} />
      <Styled.Content className="content">
        {!isHomePage ? (
          <PathName
            staticPathName={staticPathName}
            pathNames={pathNames}
            pathName={activePathName}
            setPathName={updatePathName}
            hasChanges={hasPathNameChanges}
          />
        ) : null}
        <PageMetaData
          isHomePage={isHomePage}
          isUsedForAll={useForAll}
          metaTitle={title}
          metaDescription={description}
          metaKeywords={keywords}
          updateMetaData={updateMetaData}
          setActiveTab={_setActiveTab}
        />
      </Styled.Content>
      <Footer
        isSaveButtonDisabled={isSaveBtnDisabled}
        onDiscard={onDiscard}
        onSave={onSave}
      />
    </Styled.Container>
  )
}

const mapStateToProps = state => ({
  pagesMetaData: getPagesMeta(state),
  pages: getAllPages(state)
})

export default connect(mapStateToProps, {
  changePageNames,
  changePageSettings
})(PageSettingsContainer)
